<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="'Dodawanie nagród i certyfikatów'">
        <template slot="content" slot-scope="{ setLoading }">
          <AwardsAndCertificates @loading="setLoading" />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import AwardsAndCertificates from "@/components/AwardsAndCertificates/AwardsAndCertificates.vue";

export default {
  name: "AwardsAndCertificatesAddView",
  components: {
    Ibox,
    AwardsAndCertificates
  }
};
</script>
