<template>
  <div class="tabs-container">
    <ul class="nav nav-tabs" role="tablist">
      <li>
        <a
          class="nav-link"
          :class="{ active: lng === 'pl' }"
          data-toggle="tab"
          href="#award"
          v-if="lng === 'pl'"
          @click="clickHandler('nagroda')"
          >Nagroda</a
        >
      </li>
      <li>
        <a
          class="nav-link"
          :class="{ active: lng !== 'pl' }"
          data-toggle="tab"
          href="#certificate"
          @click="clickHandler('certyfikat')"
          >Certyfikat</a
        >
      </li>
    </ul>
    <div class="tab-content">
      <div
        role="tabpanel"
        id="award"
        class="tab-pane"
        :class="{ active: lng === 'pl' }"
      >
        <form :data-vv-scope="`formTab-1`">
          <div class="panel-body">
            <div class="form-group row">
              <small
                v-show="errors.has('visible')"
                class="row text-danger col-12 mx-0"
              >
                {{ $t("field_required") }}
              </small>
              <label class="col-sm-1 col-form-label"
                ><strong>Widoczność</strong></label
              >
              <div class="form-group col-sm-5">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="visible"
                  name="visible"
                  v-model="form.visible"
                  true-value="1"
                  false-value="0"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="form-group col-sm-8"
                :class="{ 'has-error': errors.has('title') }"
              >
                <label> Tytuł* </label>
                <input
                  class="form-control"
                  type="string"
                  name="title"
                  v-model="form.title"
                  autocomplete="off"
                  v-validate="'required'"
                  :data-vv-scope="`formTab-1`"
                />
                <small
                  v-show="errors.has('formTab-1.title')"
                  class="text-danger"
                >
                  {{ $t("field_required") }}
                </small>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-8">
                <label> Opis </label>
                <textarea
                  class="form-control"
                  type="text"
                  name="copy"
                  size="1000"
                  v-model="form.copy"
                  autocomplete="off"
                  :data-vv-scope="`formTab-1`"
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-8">
                <label> URL produktu </label>
                <p>
                  <small
                    >Np.
                    https://www.neonail.pl/katalog/manicure/lakiery/baza-top-hybrydowy/baza-hybrydowa-baby-boomer-base-rose-base-72-ml.html</small
                  >
                </p>
                <input
                  class="form-control"
                  type="string"
                  name="product_url"
                  v-model="form.product_url"
                  autocomplete="off"
                  :data-vv-scope="`formTab-1`"
                />
              </div>
            </div>

            <div class="row">
              <div
                class="form-group col-sm-12"
                :class="{ 'has-error': errors.has('formTab-1.file') }"
              >
                <label>Plik*</label>
                <p>
                  <small
                    >Upewnij się, że w nazwie pliku nie ma znaków specjalnych, a
                    w miejscach spacji jest znak: -
                  </small>
                </p>
                <div>
                  <input type="file" @change="uploadAwardFile" ref="file" />
                </div>
                <small v-show="errors.has('file')" class="text-danger">
                  {{ $t("field_required") }}
                </small>
              </div>
            </div>
            <p><small> </small></p>
            <p><small class="text-danger">* - pola obowiązkowe</small></p>
            <div class="row">
              <div class="col-sm-12">
                <button
                  class="btn btn-primary ml-1"
                  type="submit"
                  @click.prevent="submitFile(`formTab-1`)"
                >
                  {{ $t("save") }}
                </button>
                <button
                  type="button"
                  class="btn btn-w-m btn-link"
                  @click="goToWebsite()"
                >
                  Przejdź do witryny
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div
        role="tabpanel"
        id="certificate"
        class="tab-pane"
        :class="{ active: lng !== 'pl' }"
      >
        <form :data-vv-scope="`formTab-2`">
          <div class="panel-body">
            <div class="row">
              <div
                class="form-group col-sm-8"
                :class="{ 'has-error': errors.has('title') }"
              >
                <label> Tytuł* </label>
                <input
                  class="form-control"
                  type="string"
                  name="title"
                  v-model="form.title"
                  autocomplete="off"
                  v-validate="'required'"
                  :data-vv-scope="`formTab-2`"
                />
                <small
                  v-show="errors.has('formTab-2.title')"
                  class="text-danger"
                >
                  {{ $t("field_required") }}
                </small>
              </div>
            </div>
            <div class="row">
              <div
                class="form-group col-sm-12"
                :class="{ 'has-error': errors.has('file') }"
              >
                <label>Miniaturka*</label>
                <div>
                  <input
                    type="file"
                    @change="uploadCertificateImg"
                    ref="certificate_img"
                    :data-vv-scope="`formTab-2`"
                  />
                </div>
                <small
                  v-show="errors.has('formTab-2.file')"
                  class="text-danger"
                >
                  {{ $t("field_required") }}
                </small>
              </div>
            </div>
            <div class="row">
              <div
                class="form-group col-sm-12"
                :class="{ 'has-error': errors.has('file') }"
              >
                <label>Plik*</label>
                <p>
                  <small
                    >Upewnij się, że w nazwie plików nie ma znaków specjalnych,
                    a w miejscach spacji jest znak: -
                  </small>
                </p>
                <div>
                  <input
                    type="file"
                    @change="uploadCertificateFiles"
                    ref="file_pl"
                    :data-vv-scope="`formTab-2`"
                  />
                </div>
                <small
                  v-show="errors.has('formTab-2.file')"
                  class="text-danger"
                >
                  {{ $t("field_required") }}
                </small>
              </div>
            </div>
            <p>
              <small
                >Jeśli nie ma plików w językach poniżej, wystarczy dodanie
                jednego do Plik*.
              </small>
            </p>
            <div class="row">
              <div class="form-group col-sm-12">
                <label class="text-muted">Plik EN</label>
                <div>
                  <input
                    type="file"
                    @change="uploadCertificateFileEN"
                    ref="file_en"
                    :data-vv-scope="`formTab-2`"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-sm-12">
                <label class="text-muted">Plik DE</label>
                <div>
                  <input
                    type="file"
                    @change="uploadCertificateFileDE"
                    ref="file_de"
                    :data-vv-scope="`formTab-2`"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-sm-12">
                <label class="text-muted">Plik RU</label>
                <div>
                  <input
                    type="file"
                    @change="uploadCertificateFileRU"
                    ref="file_ru"
                    :data-vv-scope="`formTab-2`"
                  />
                </div>
              </div>
            </div>

            <p><small> </small></p>
            <p><small class="text-danger">* - pola obowiązkowe</small></p>
            <div class="row">
              <div class="col-sm-12">
                <button
                  class="btn btn-primary ml-1"
                  type="submit"
                  @click.prevent="
                    uploadCertificateFileEN();
                    uploadCertificateFileDE();
                    uploadCertificateFileRU();
                    submitFile(`formTab-2`);
                  "
                >
                  {{ $t("save") }}
                </button>
                <button
                  type="button"
                  class="btn btn-w-m btn-link"
                  @click="goToWebsite()"
                >
                  Przejdź do witryny
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AwardsAndCertificates",
  data() {
    return {
      form: {
        title: "",
        copy: "",
        product_url: "",
        file: null,
        file_en: null,
        file_de: null,
        file_ru: null,
        award: 1,
        certificate: 0,
        certificate_img: null,
        visible: 1,
      },
      lng: this.$route.params.shop_lang,
    };
  },
  computed: {
    urlInsert() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/awards-and-certificates/insert`;
    },
  },
  created() {
    this.$emit("loading", false);
    if (this.lng !== "pl") {
      this.form.award = 0;
      this.form.certificate = 1;
    }
  },
  methods: {
    clickHandler(str) {
      if (str == "nagroda") {
        this.form.award = 1;
        this.form.certificate = 0;
      } else if (str == "certyfikat") {
        this.form.award = 0;
        this.form.certificate = 1;
      }
    },
    goToWebsite() {
      switch (this.$route.params.shop_lang) {
        case "pl":
          window.open(
            "https://www.neonail.pl/certyfikaty-i-nagrody.html",
            "_blank"
          );
          break;
        case "de":
          window.open(
            "https://neonail.de/zertifikate-und-erklarungen.html",
            "_blank"
          );
          break;
        case "es":
          window.open(
            "https://www.neonail-espana.es/certificados-y-declaraciones.html",
            "_blank"
          );
          break;
        case "it":
          window.open(
            "https://neonail.it/certificate-e-dichiarazioni.html",
            "_blank"
          );
          break;
        case "fr":
          window.open(
            "https://neonail.it/certificate-e-dichiarazioni.html",
            "_blank"
          );
          break;
        case "nl":
          window.open("https://neonail.nl/certificaten.html", "_blank");
          break;
      }
    },
    resetInput() {
      this.form.title = "";
      this.form.copy = "";
      this.form.product_url = "";
      this.form.file = null;
      this.form.file_en = null;
      this.form.file_de = null;
      this.form.file_ru = null;
    },
    uploadAwardFile() {
      this.form.file = this.$refs.file.files[0];
      this.form.file_en = this.form.file;
      this.form.file_de = this.form.file;
      this.form.file_ru = this.form.file;
    },
    uploadCertificateFiles() {
      this.form.file = this.$refs.file_pl.files[0];
    },
    uploadCertificateFileEN() {
      if (this.form.file_en === null || this.form.file_en === undefined) {
        this.form.file_en = this.form.file;
      } else {
        this.form.file_en = this.$refs.file_en.files[0];
      }
    },
    uploadCertificateFileDE() {
      if (this.form.file_de === null || this.form.file_de === undefined) {
        this.form.file_de = this.form.file;
      } else {
        this.form.file_de = this.$refs.file_de.files[0];
      }
    },
    uploadCertificateFileRU() {
      if (this.form.file_ru === null || this.form.file_ru === undefined) {
        this.form.file_ru = this.form.file;
      } else {
        this.form.file_ru = this.$refs.file_ru.files[0];
      }
    },
    uploadCertificateImg() {
      this.form.certificate_img = this.$refs.certificate_img.files[0];
    },
    submitFile(form_name) {
      const formData = new FormData();
      this.$validator.validateAll(form_name).then((result) => {
        if (result) {
          this.$emit("loading", true);
          formData.append("title", this.form.title);
          formData.append("copy", this.form.copy);
          formData.append("product_url", this.form.product_url);
          formData.append("file", this.form.file);
          formData.append("file_en", this.form.file_en);
          formData.append("file_de", this.form.file_de);
          formData.append("file_ru", this.form.file_ru);
          formData.append("award", this.form.award);
          formData.append("certificate", this.form.certificate);
          formData.append("certificate_img", this.form.certificate_img);
          formData.append("lang", this.$route.params.shop_lang);
          formData.append("visible", this.form.visible);
          const headers = { "Content-Type": "multipart/form-data" };
          this.$http
            .post(this.urlInsert, formData, { headers })
            .then((res) => {
              res.data.files;
              res.status;
              this.$emit("loading", false);
              this.$toastr.success("Zapis przebiegł pomyślnie!");
              this.resetInput();
            })
            .catch((err) => {
              this.$toastr.error(err);
              this.$emit("loading", false);
              this.resetInput();
            });
        }
      });
    },
  },
};
</script>
<style>
small {
  color: rgb(169, 169, 169);
}
</style>
